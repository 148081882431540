import { compose } from "redux";
import { withFirebase } from "react-redux-firebase";
import ui from "react-redux-ui-tools";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getOrganization } from "ducks/organizations";
import OrganizationView from "./AdminView";
import i18n from "lib/i18n";
import de from "./i18n/de";
import en from "./i18n/en";
i18n.addResourceBundle("de", "adminView", de, true);
i18n.addResourceBundle("en", "adminView", en, true);

const uiConfig = {
  key: "organizationView",
  persist: true,
  state: {
    showMobileDrawer: false,
    showOwnOrganizationSettings: false,
    showOwnOrganizationNotificationSettings: false
  }
};

const mapStateToProps = (state, ownProps) => ({
  auth: state.firebase.auth,
  organization: getOrganization(state, ownProps.match.params.organizationId)
});

export default compose(
  ui(uiConfig),
  withFirebase,
  withTranslation("adminView"),
  connect(mapStateToProps)
)(OrganizationView);
