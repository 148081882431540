import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { get, isString } from "lodash";
import { useTranslation } from "react-i18next";

import FullscreenLoader from "components/ui/FullscreenLoader";

function AppLoading({ auth, children, classes, profile }) {
  const { i18n } = useTranslation();
  const [languageLoaded, setLanguageLoaded] = useState(true);
  // Build Todos list if todos exist and are loaded
  if (
    auth === undefined ||
    get(auth, "isLoaded") === false ||
    get(profile, "isLoaded") === false ||
    !languageLoaded
  ) {
    return <FullscreenLoader />;
  }
  if (isString(profile.language) && profile.language !== i18n.language) {
    console.log("Set language to profile language", profile.language);
    setLanguageLoaded(false);
    i18n.changeLanguage(profile.language).then(() => setLanguageLoaded(true));
  }
  return children;
}

const enhance = compose(
  connect(state => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }))
);

export default enhance(React.memo(AppLoading));
