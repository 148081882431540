import { ActionType } from "redux-promise-middleware";
import { find, get, has } from "lodash";

export function loadOrganizations() {
  return (dispatch, getState, { getFirebase }) => {
    const loadOrganizationsFn = getFirebase()
      .functions()
      .httpsCallable("getAllOrganizations");
    return dispatch({
      type: "LOAD",
      payload: loadOrganizationsFn()
    });
  };
}

export function loadOrganization(organizationId) {
  return (dispatch, getState, { getFirebase }) => {
    const loadOrganizationFn = getFirebase()
      .functions()
      .httpsCallable("getOrganization");
    return dispatch({
      type: "LOAD_ORGANIZATION",
      payload: loadOrganizationFn({ organizationId })
    });
  };
}

export function loadOrganizationMembers(organizationId) {
  return (dispatch, getState, { getFirebase }) => {
    const loadOrganizationMembersFn = getFirebase()
      .functions()
      .httpsCallable("getOrganizationMembers");
    return dispatch({
      type: "LOAD_ORGANIZATION_MEMBERS",
      payload: loadOrganizationMembersFn({ organizationId }).then(result => ({
        organizationId,
        members: result.data
      }))
    });
  };
}

export function loadOrganizationQuestions(organizationId) {
  return (dispatch, getState, { getFirebase }) => {
    const loadOrganizationQuestionsFn = getFirebase()
      .functions()
      .httpsCallable("getOrganizationQuestions");
    return dispatch({
      type: "LOAD_ORGANIZATION_QUESTIONS",
      payload: loadOrganizationQuestionsFn({ organizationId }).then(result => ({
        organizationId,
        questions: result.data
      }))
    });
  };
}

export function loadOrganizationQuestionRoundInformation(organizationId) {
  return (dispatch, getState, { getFirebase }) => {
    const loadOrganizationQuestionRoundInformationFn = getFirebase()
      .functions()
      .httpsCallable("getOrganizationQuestionRoundInformation");
    return dispatch({
      type: "LOAD_ORGANIZATION_QUESTION_ROUND_INFORMATION",
      payload: loadOrganizationQuestionRoundInformationFn({
        organizationId
      }).then(result => ({
        organizationId,
        ...result.data
      }))
    });
  };
}

const initialState = {
  isLoading: false,
  organizations: [],
  isLoadingOrganization: false,
  isLoadingMembers: false,
  members: {},
  isLoadingQuestions: false,
  questions: {},
  schedules: {},
  questionRounds: {}
};

const ACTION_HANDLERS = {
  // LOAD
  [`LOAD_${ActionType.Pending}`]: (state, action) => ({
    ...state,
    isLoading: true
  }),
  [`LOAD_${ActionType.Fulfilled}`]: (state, action) => ({
    ...state,
    isLoading: false,
    organizations: action.payload.data.organizations
  }),
  [`LOAD_${ActionType.Rejected}`]: (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload
  }),
  // LOAD_ORGANIZATION
  [`LOAD_ORGANIZATION_${ActionType.Pending}`]: (state, action) => ({
    ...state,
    isLoadingOrganization: true
  }),
  [`LOAD_ORGANIZATION_${ActionType.Fulfilled}`]: (state, action) => ({
    ...state,
    isLoadingOrganization: false,
    organizations: [...state.organizations, action.payload.data]
  }),
  [`LOAD_ORGANIZATION_${ActionType.Rejected}`]: (state, action) => ({
    ...state,
    isLoadingOrganization: false,
    error: action.payload
  }),
  // LOAD_ORGANIZATION_MEMBERS
  [`LOAD_ORGANIZATION_MEMBERS_${ActionType.Pending}`]: (state, action) => ({
    ...state,
    isLoadingMembers: true
  }),
  [`LOAD_ORGANIZATION_MEMBERS_${ActionType.Fulfilled}`]: (state, action) => ({
    ...state,
    isLoadingMembers: false,
    members: {
      ...state.organizationMembers,
      [action.payload.organizationId]: action.payload.members
    }
  }),
  [`LOAD_ORGANIZATION_MEMBERS_${ActionType.Rejected}`]: (state, action) => ({
    ...state,
    isLoadingMembers: false,
    error: action.payload
  }),
  // LOAD_ORGANIZATION_QUESTIONS
  [`LOAD_ORGANIZATION_QUESTIONS_${ActionType.Pending}`]: (state, action) => ({
    ...state,
    isLoadingQuestions: true
  }),
  [`LOAD_ORGANIZATION_QUESTIONS_${ActionType.Fulfilled}`]: (state, action) => ({
    ...state,
    isLoadingQuestions: false,
    questions: {
      ...state.questions,
      [action.payload.organizationId]: action.payload.questions
    }
  }),
  [`LOAD_ORGANIZATION_QUESTIONS_${ActionType.Rejected}`]: (state, action) => ({
    ...state,
    isLoadingQuestions: false,
    error: action.payload
  }),
  // LOAD_ORGANIZATION_QUESTION_ROUND_INFORMATION
  [`LOAD_ORGANIZATION_QUESTION_ROUND_INFORMATION_${ActionType.Fulfilled}`]: (
    state,
    action
  ) => ({
    ...state,
    schedules: {
      ...state.schedules,
      [action.payload.organizationId]: action.payload.schedules
    },
    questionRounds: {
      ...state.questionRounds,
      [action.payload.organizationId]: action.payload.questionRounds
    }
  }),
  [`LOAD_ORGANIZATION_QUESTION_ROUND_INFORMATION_${ActionType.Rejected}`]: (
    state,
    action
  ) => ({
    ...state,
    error: action.payload
  })
};

export default function appReducer(
  state: ?StateType = initialState,
  action: Object
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

export const getAllOrganizations = state => state.organizations.organizations;
export const isLoadingOrganizations = state => state.organizations.isLoading;
export const getOrganization = (state, organizationId) =>
  find(state.organizations.organizations, { id: organizationId });
export const isLoadingOrganization = state =>
  state.organizations.isLoadingOrganization;
export const isLoadingMembers = state => state.organizations.isLoadingMembers;
export const getOrganizationMembers = (state, organizationId) =>
  get(state.organizations.members, organizationId, []);
export const isLoadingOrganizationQuestions = state =>
  state.organizations.isLoadingQuestions;
export const getOrganizationQuestions = (state, organizationId) =>
  get(state.organizations.questions, organizationId);
export const getQuestionRounds = (state, organizationId) =>
  get(state.organizations.questionRounds, organizationId, []);
export const getSchedules = (state, organizationId) =>
  get(state.organizations.schedules, organizationId);
export const hasLoadedRoundInformation = (state, organizationId) =>
  has(state.organizations.schedules, organizationId) &&
  has(state.organizations.questionRounds, organizationId);
