// @flow
import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withErrorBoundary } from "react-error-boundary";
import Loadable from "react-loadable";
import { SnackbarProvider } from "notistack";

import { handleReactError } from "lib/sentry";
// $FlowFixMe
import "moment/locale/de";

import theme from "theme";
import NewVersionDialog from "components/NewVersionDialog";

import AppError from "components/AppError";
import NotFound from "components/ui/NotFound";
import PrivateRoute from "./PrivateRoute";
import { PrivateRoutes } from "./Routes";
import AppLoading from "./AppLoading";

const routes = () => (
  <Switch>
    <Route
      exact
      path="/login"
      component={Loadable({
        loading: () => null,
        loader: () => import("components/pages/SignIn")
      })}
    />
    <PrivateRoute path="/" render={() => <PrivateRoutes />} />
    {/* Not found */}
    <Route component={NotFound} />
  </Switch>
);

const App = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <NewVersionDialog />
    <AppLoading>
      <Router>
        <SnackbarProvider maxSnack={2}>{routes()} </SnackbarProvider>
      </Router>
    </AppLoading>
  </ThemeProvider>
);

export default withErrorBoundary(App, AppError, handleReactError);
