import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "core-js/features/array/find";
import "core-js/features/array/includes";

import { reportError } from "./lib/sentry";

function startApp() {
  try {
    const app = require("./app").default;
    console.log(
      `%c
##    ## ##     ## ##       ######## #### ######## ##    ##
##   ##  ##     ## ##          ##     ##  ##        ##  ##               Built with React, Redux, Firebase and others.
##  ##   ##     ## ##          ##     ##  ##         ####                See our techstack: https://stackshare.io/kultify/kultify
#####    ##     ## ##          ##     ##  ######      ##                 Made with ❤️ in Nürnberg
##  ##   ##     ## ##          ##     ##  ##          ##                 Version ${
        process.env.REACT_APP_VERSION
      }
##   ##  ##     ## ##          ##     ##  ##          ##                 For more visit https://kultify.de
##    ##  #######  ########    ##    #### ##          ##
`,
      "color: #36A0F2"
    );

    app();
  } catch (err) {
    reportError(err);
  }
}

startApp();
