// @flow
import React from "react";
import { Route, Redirect } from "react-router";
import { connect } from "react-redux";
import { get } from "lodash";

type PrivateRouteProps = {
  render: Function,
  isLoggedIn: boolean
};

const PrivateRoute = ({ render, isLoggedIn, ...rest }: PrivateRouteProps) => (
  <Route
    {...rest}
    render={props => {
      const redirectToJoin = () => (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      );
      if (!isLoggedIn) {
        return redirectToJoin();
      }
      return render();
    }}
  />
);

const mapStateToProps = state => ({
  isLoggedIn: !!get(state, "firebase.auth.uid")
});

export default connect(mapStateToProps)(PrivateRoute);
