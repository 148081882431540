import React from "react";

import { Helmet } from "react-helmet";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import Toolbar from "./Toolbar";

const styles = theme => ({
  content: {
    marginTop: theme.spacing(12),
    paddingBottom: theme.spacing(4)
  }
});

const AdminView = ({ t, auth, classes, firebase, children, organization }) => {
  return (
    <div>
      <Helmet>
        <title>{t("helmetTitle")}</title>
      </Helmet>
      <Toolbar
        auth={auth}
        firebase={firebase}
        t={t}
        organization={organization}
      />

      <Container className={classes.content}>{children}</Container>
    </div>
  );
};

export default withStyles(styles)(React.memo(AdminView));
