import React from "react";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography/Typography";
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";

const styles = theme => ({
  fullScreen: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  maxWidth: {
    maxWidth: "72ch"
  },
  gif: {
    marginBottom: "1rem"
  }
});

const AppError = ({ t, classes }) => (
  <div className={classes.fullScreen}>
    <CssBaseline />
    <img
      className={classes.gif}
      alt=""
      src="https://media.giphy.com/media/3oKIPnAiaMCws8nOsE/giphy.gif"
    />
    <Typography
      className={classes.maxWidth}
      color="textSecondary"
      align="center"
      gutterBottom
      variant="subheading"
      dangerouslySetInnerHTML={{ __html: t("subtitle") }}
    />
    <Button
      variant="outlined"
      component={props => (
        <a href="/" {...props}>
          {t("backToWebsite")}
        </a>
      )}
    >
      {t("backToWebsite")}
    </Button>
  </div>
);

export default withStyles(styles)(AppError);
