import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import i18n from "lib/i18n";

import { isNewVersionAvailable, getServiceWorkerRegistration } from "ducks/app";
import NewVersionDialog from "./NewVersionDialog";

import de from "./i18n/de";
import en from "./i18n/en";
i18n.addResourceBundle("de", "newVersionDialog", de, true);
i18n.addResourceBundle("en", "newVersionDialog", en, true);

const mapStateToProps = state => ({
  newVersionAvailable: isNewVersionAvailable(state),
  registration: getServiceWorkerRegistration(state)
});

export default connect(mapStateToProps)(
  withTranslation("newVersionDialog")(NewVersionDialog)
);
