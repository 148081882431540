// @flow
import React from "react";
import { Route, Switch } from "react-router";
import Loadable from "react-loadable";

import AdminView from "components/AdminView";
import NotFound from "components/ui/NotFound";
import reportError from "lib/sentry";

const Loader = props => {
  if (props.error) {
    console.error(props.error);
    reportError(props.error);
  }
  return null;
};

const LoadableOrganizationsOverviewPage = Loadable({
  loading: Loader,
  loader: () => import("components/pages/OrganizationsOverview")
});

const LoadableOrganizationPage = Loadable({
  loading: Loader,
  loader: () => import("components/pages/Organization")
});

const renderWithAdminView = Component => props => (
  <AdminView {...props}>
    <Component {...props} />
  </AdminView>
);

export const PrivateRoutes = () => (
  <Switch>
    <Route
      exact
      path="/"
      render={renderWithAdminView(LoadableOrganizationsOverviewPage)}
    />
    <Route
      exact
      path="/orga/:organizationId"
      render={renderWithAdminView(LoadableOrganizationPage)}
    />

    {/* Not found */}
    <Route component={NotFound} />
  </Switch>
);
