import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import { reducer as reduxUi } from "react-redux-ui-tools";

import appReducer from "ducks/app";
import organizationsReducer from "ducks/organizations";

export const makeRootReducer = asyncReducers => {
  return combineReducers({
    ...asyncReducers,
    ui: reduxUi,
    // app
    app: appReducer,
    organizations: organizationsReducer,
    // firebase
    firebase: firebaseReducer,
    firestore: firestoreReducer
  });
};

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;
  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
