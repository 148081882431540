import "typeface-nunito";
import { createMuiTheme } from "@material-ui/core";

export const kultifyTheme = {
  colors: {
    //white
    white: "white",
    // black
    black: "#2C2C3A",
    grayA: "#4B4B5E",
    grayB: "#80818E",
    grayC: "#B7B7BE",
    grayD: "#D2D2D6",
    grayE: "#ECEDEE",
    grayF: "#F5F6F6",
    // dark blue
    darkBlueDarkest: "#0022DA",
    darkBlueDark: "#1435E4",
    darkBlue: "#3850E7",
    darkBlueBright: "#7583EE",
    // blue
    blueDarkest: "#1175C7",
    blueDark: "#1A8FF0",
    blue: "#36A0F2",
    blueLight: "#66B5F2",
    // light blue
    lightBlue: "#8ADFF3",
    // green
    darkGreenDarkest: "#1D9D53",
    darkGreen: "#37C975",
    greenDarkest: "#62AB46",
    green: "#96DD7A",
    greenLight: "#BBEB9C",
    lightGreen: "#CAF0A9",
    // red
    redDark: "#DA280A",
    red: "#EA5041",
    redLight: "#FA756A",
    redBrightest: "#FB9D95",
    // yellow
    yellowDark: "#D4AC06",
    yellow: "#F2D467",
    yellowLight: "#FEE079",
    yellowBrightest: "#FFEBAA",
    // orange
    orange: "#EF9865",
    orangeDark: "#D16A2C",
    orangeLight: "#FCAD81",
    // rose
    rose: "#F4919A",
    // pink
    pinkDarkest: "#961944",
    pinkDark: "#B83162",
    pink: "#CF527F",
    pinkLight: "#E378A0",
    // silver
    boxSilver: "#EDF1FB",
    // violet
    violet: "#933CA2",
    // purple
    purple: "#6A47A9",
    // basic analysis
    basicAnalysisBackground: "white",
    // Ribbon
    ribbonGray: "#94949C",
    // TAG Color
    tagBackground: "#80818E"
  }
};

export const materialTheme = createMuiTheme({
  typography: {
    fontFamily: "Nunito,Roboto,sans-serif",
    body2: {
      fontWeight: 500
    },
    h6: {
      fontWeight: 600
    },
    subtitle2: {
      fontWeight: 600
    },
    button: {
      fontWeight: 600
    }
  },
  palette: {
    primary: {
      light: kultifyTheme.colors.lightBlue,
      main: kultifyTheme.colors.blue,
      dark: kultifyTheme.colors.darkBlue,
      contrastText: "#fff"
    },
    secondary: {
      light: kultifyTheme.colors.green,
      main: kultifyTheme.colors.darkGreen,
      dark: kultifyTheme.colors.darkGreenDarkest,
      contrastText: "#fff"
    },
    error: {
      light: kultifyTheme.colors.redLight,
      main: kultifyTheme.colors.red,
      dark: kultifyTheme.colors.redDark,
      contrastText: "#fff"
    }
  },
  kultify: {
    palette: {
      ...kultifyTheme.colors
    }
  }
});

export default materialTheme;
