// @flow
import React from "react";
import { toRenderProps, withState } from "recompose";

import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SignoutIcon from "@material-ui/icons/PowerSettingsNew";
// import SettingsIcon from "@material-ui/icons/Settings";
import PersonIcon from "@material-ui/icons/Person";

import ButtonLink from "components/ui/ButtonLink";

export type ToolbarProps = {
  t: Function,
  classes: Object,
  auth: Object,
  firebase: Object,
  children: any,
  organization: ?Object
};

const styles = theme => ({
  paper: {},
  logo: {
    marginTop: "2rem",
    marginBottom: "1.5rem"
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  grow: {
    flexGrow: 1
  },
  userInfo: {
    display: "flex",
    alignItems: "center"
  },
  menuButton: {
    color: "white",
    marginLeft: theme.spacing(1)
  }
});

const WithMenuState = toRenderProps(
  withState("anchorEl", "updateAnchorEl", null)
);

const UserMenu = ({ onLogout, classes, auth, t }) => (
  <WithMenuState>
    {({ anchorEl, updateAnchorEl }) => {
      const open = Boolean(anchorEl);
      const handleClose = () => {
        updateAnchorEl(null);
      };
      return (
        <React.Fragment>
          <Button
            color="inherit"
            aria-owns={open ? "render-props-menu" : null}
            aria-haspopup="true"
            onClick={event => {
              updateAnchorEl(event.currentTarget);
            }}
          >
            <PersonIcon color="inherit" className={classes.buttonIcon} />
            {auth.email}
          </Button>
          <Menu
            id="render-props-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                onLogout();
              }}
            >
              <ListItemIcon className={classes.icon}>
                <SignoutIcon />
              </ListItemIcon>
              <ListItemText color="inherit" primary={t("logout")} />
            </MenuItem>
          </Menu>
        </React.Fragment>
      );
    }}
  </WithMenuState>
);

export const AdminToolbar = ({
  classes,
  auth,
  firebase,
  t,
  organization
}: ToolbarProps) => {
  return (
    <AppBar>
      <Toolbar>
        {organization ? (
          <div>
            <ButtonLink to="/" color="inherit">
              <ChevronLeftIcon />
              {organization.name}
            </ButtonLink>
          </div>
        ) : null}

        <div className={classes.grow} />
        <UserMenu
          t={t}
          auth={auth}
          onLogout={() => firebase.logout()}
          classes={classes}
          className={classes.menuButton}
        />
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(AdminToolbar);
