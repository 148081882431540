import i18n from "i18next";
import moment from "moment";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  fallbackLng: ["en", "de"],
  debug: process.env.NODE_ENV !== "production",
  interpolation: {
    escapeValue: false, // not needed for react
    formatSeparator: ",",
    format: function(value, format, lng) {
      if (format === "uppercase") return value.toUpperCase();
      if (value instanceof Date) return moment(value).format(format);
      return value;
    }
  },
  react: {
    useSuspense: false,
    withRef: true
  }
});

export default i18n;
